import type { ArchivedCalendarsQuery } from 'types/graphql'
import type { CellSuccessProps } from '@redwoodjs/web'

import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'
import { QUERY as CALENDARS_QUERY } from 'src/components/UserCalendarsCell'
import Modal from 'src/components/Modal'
import MetaHead from 'src/components/MetaHead'
import { BiArchive, BiUndo, BiTrash } from 'react-icons/bi'

export const QUERY = gql`
  query ArchivedCalendarsQuery {
    archivedCalendars {
      id
      name
      todos {
        id
        title
      }
    }
  }
`

export const Loading = () => <></>

export const Empty = () => <></>

const UNARCHIVE_CALENDAR_MUTATION = gql`
  mutation UnarchiveCalendarMutation($id: String!, $archived: Boolean!) {
    unarchiveCalendar: archiveCalendar(id: $id, archived: $archived) {
      id
    }
  }
`

const DELETE_CALENDAR_MUTATION = gql`
  mutation DeleteCalendarMutation($id: String!) {
    deleteCalendar(id: $id) {
      id
    }
  }
`

export const Success = ({
  archivedCalendars,
}: CellSuccessProps<ArchivedCalendarsQuery>) => {
  const [isOpen, setIsOpen] = React.useState(false)
  const [isDeleteOpen, setIsDeleteOpen] = React.useState(false)

  const [unarchiveCalendar] = useMutation(UNARCHIVE_CALENDAR_MUTATION, {
    onCompleted: () => {
      setIsOpen(false)
      toast.success('Calendar restored')
    },
    refetchQueries: [{ query: QUERY }, { query: CALENDARS_QUERY }],
    awaitRefetchQueries: true,
  })

  const onUnarchiveClick = ({ id }) => {
    unarchiveCalendar({ variables: { id, archived: false } })
  }

  return (
    <>
      <button onClick={() => setIsOpen(true)} className="p-1 rounded-lg">
        <BiArchive />
        <span className="sr-only">Archived Calendars</span>
      </button>

      <Modal title="Archived calendars" isOpen={isOpen} setIsOpen={setIsOpen}>
        <MetaHead title="Archived calendars" />
        <table className="border-2 border-gray-600 w-full text-sm">
          <thead className="bg-gray-600 text-gray-100">
            <tr>
              <th>Name</th>
              <th>Todos</th>
              <th>
                <span className="sr-only">Actions</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {archivedCalendars.map((calendar, i: number) => (
              <tr key={i} className={`${i % 2 !== 0 ? '' : 'bg-gray-100 '}`}>
                <td>{calendar.name}</td>
                <td>{calendar.todos.length}</td>
                <td className="flex items-center justify-end">
                  <button
                    onClick={() => onUnarchiveClick({ id: calendar.id })}
                    title="Restore calendar"
                    className="text-blue-600 hover:text-blue-700 flex items-center p-1 text-xs font-semibold rounded-lg transition-colors duration-300"
                  >
                    <BiUndo className="w-4 h-4" />
                    <span className="sr-only">Restore calendar</span>
                  </button>
                  <DeleteCalendar
                    isDeleteOpen={isDeleteOpen}
                    setIsDeleteOpen={setIsDeleteOpen}
                    calendar={calendar}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Modal>
    </>
  )
}

const DeleteCalendar = ({ isDeleteOpen, setIsDeleteOpen, calendar }) => {
  const [deleteCalendar] = useMutation(DELETE_CALENDAR_MUTATION, {
    onCompleted: () => {
      setIsDeleteOpen(false)
      toast.success('Calendar deleted')
    },
    refetchQueries: [{ query: QUERY }],
    awaitRefetchQueries: true,
  })

  const onDeleteClick = ({ id }) => {
    deleteCalendar({ variables: { id } })
  }

  return (
    <>
      <button
        onClick={() => setIsDeleteOpen(true)}
        title="Delete calendar"
        className="text-red-600 hover:text-red-700 flex items-center p-1 text-xs font-semibold rounded-lg transition-colors duration-300"
      >
        <BiTrash className="w-4 h-4" />
        <span className="sr-only">Delete calendar</span>
      </button>
      <Modal
        title="Delete Calendar"
        isOpen={isDeleteOpen}
        setIsOpen={setIsDeleteOpen}
        color="red"
      >
        <MetaHead title={`Delete ${calendar.name}`} />
        <p>
          Are your sure you want to delete <em>{calendar.name}</em> permanently?
          This action cannot be reversed.
        </p>
        <div className="btn-group">
          <button
            onClick={() => setIsDeleteOpen(false)}
            className="btn btn-gray"
          >
            Cancel
          </button>
          <button
            onClick={() => onDeleteClick({ id: calendar.id })}
            className="btn btn-red"
          >
            Delete
          </button>
        </div>
      </Modal>
    </>
  )
}
