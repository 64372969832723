import { useAuth } from '@redwoodjs/auth'
import UserCalendarsCell from 'src/components/UserCalendarsCell'
import SubscriptionStatusCell from 'src/components/SubscriptionStatusCell'

// type CalendarStateProps = {
//   calendars: any[]
//   setCalendars: React.Dispatch<React.SetStateAction<any[]>>
// }

export const CalendarsContext = React.createContext({
  calendars: [],
  setCalendars: () => {},
})

export const SharedCalendarsContext = React.createContext({
  sharedCalendars: [],
  setSharedCalendars: () => {},
})

export const EditingTodoContext = React.createContext({
  isDisabled: false,
  setIsDisabled: () => {},
})

export const ViewCompletedContext = React.createContext({
  viewCompleted: true,
  setViewCompleted: () => {},
})

export const SubscriptionContext = React.createContext({
  isSubscribed: false,
  setIsSubscribed: () => {},
})

const CalendarProvider = ({ children }) => {
  const { isAuthenticated } = useAuth()

  const [calendars, setCalendars] = React.useState([])
  const [sharedCalendars, setSharedCalendars] = React.useState([])
  const [isDisabled, setIsDisabled] = React.useState(false)
  const [viewCompleted, setViewCompleted] = React.useState(true)
  const [isSubscribed, setIsSubscribed] = React.useState(false)

  const calendarsValue = { calendars, setCalendars }
  const sharedCalendarsValue = { sharedCalendars, setSharedCalendars }
  const isDisabledValue = { isDisabled, setIsDisabled }
  const viewCompletedValue = { viewCompleted, setViewCompleted }
  const subscriptionStatusValue = { isSubscribed, setIsSubscribed }

  return (
    <CalendarsContext.Provider value={calendarsValue}>
      <SharedCalendarsContext.Provider value={sharedCalendarsValue}>
        <SubscriptionContext.Provider value={subscriptionStatusValue}>
          <EditingTodoContext.Provider value={isDisabledValue}>
            <ViewCompletedContext.Provider value={viewCompletedValue}>
              {isAuthenticated && (
                <>
                  <UserCalendarsCell />
                  <SubscriptionStatusCell />
                </>
              )}
              {children}
            </ViewCompletedContext.Provider>
          </EditingTodoContext.Provider>
        </SubscriptionContext.Provider>
      </SharedCalendarsContext.Provider>
    </CalendarsContext.Provider>
  )
}

export default CalendarProvider
