import type { FindSubscriptionStatusQuery } from 'types/graphql'
import type { CellSuccessProps, CellFailureProps } from '@redwoodjs/web'

import { SubscriptionContext } from 'src/components/CalendarProvider'

export const QUERY = gql`
  query FindSubscriptionStatusQuery {
    status: subscriptionStatus
  }
`

export const Loading = () => <></>

export const Empty = () => <></>

export const Success = ({
  status,
}: CellSuccessProps<FindSubscriptionStatusQuery>) => {
  const { setIsSubscribed } = React.useContext(SubscriptionContext)

  React.useEffect(() => {
    setIsSubscribed(status)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status])
  return <></>
}
