import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'
import MetaHead from 'src/components/MetaHead'
import CalendarForm from 'src/components/Calendar/CalendarForm'
import { QUERY } from 'src/components/UserCalendarsCell'

const CREATE_CALENDAR_MUTATION = gql`
  mutation CreateCalendarMutation($input: CreateCalendarInput!) {
    createCalendar(input: $input) {
      id
      name
      userId
    }
  }
`

const NewCalendar = React.forwardRef(
  ({ setNewCalendarOpen, setCurrentCalendar }, ref) => {
    const [createCalendar, { loading, error }] = useMutation(
      CREATE_CALENDAR_MUTATION,
      {
        onCompleted: (data) => {
          setNewCalendarOpen(false)
          toast.success('Calendar created')
          if (setCurrentCalendar) setCurrentCalendar(data.createCalendar)
        },
        refetchQueries: [{ query: QUERY }],
        awaitRefetchQueries: true,
      }
    )

    const onSave = (input) => {
      createCalendar({ variables: { input } })
    }

    React.useEffect(() => {
      if (error) {
        toast.error(error.message)
      }
    }, [error])

    return (
      <>
        <MetaHead title="New Calendar" />
        <CalendarForm
          onSave={onSave}
          loading={loading}
          error={error}
          ref={ref}
        />
      </>
    )
  }
)

export default NewCalendar
