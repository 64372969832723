import { useLocation } from '@redwoodjs/router'
import { Helmet } from 'react-helmet'

interface HeadProps {
  title?: string
  description?: string
  children?: React.ReactNode
}

const MetaHead = (props: HeadProps) => {
  const { pathname } = useLocation()

  const title = props.title
    ? props.title + ' | ReOccur'
    : 'ReOccur | Manage your reoccurring tasks'

  const description = props.description
    ? props.description
    : 'Manage your reoccurring tasks'

  const domain = process.env.URL ? process.env.URL : 'http://localhost:8910'

  const url = domain + pathname

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="title" content={title} />
      <meta property="og:title" content={title} />
      <meta name="twitter:title" content={title} />

      <meta name="description" content={description} />
      <meta property="og:description" content={description} />
      <meta name="twitter:description" content={description} />

      <meta property="og:url" content={url} />
      <meta name="twitter:url" content={url} />

      <meta property="og:image" content={domain + '/social-card.png'} />
      <meta
        property="og:image:secure_url"
        content={domain + '/social-card.png'}
      />
      <meta name="twitter:image" content={domain + '/social-card.png'} />

      <meta property="og:type" content="website" />

      <meta property="og:locale" content="en_US" />
      <meta property="og:image:type" content="image/png" />
      <meta property="og:image:width" content="1920" />
      <meta property="og:image:height" content="1080" />
      <meta property="og:image:alt" content="Reoccurring checkbox" />
      <meta name="twitter:image:alt" content="Reoccurring checkbox" />
      <meta name="twitter:creator" content="@reoccur_io" />
      <meta name="twitter:site" content="@reoccur_io" />
      <meta name="twitter:card" content="summary_large_image" />

      {props.children}
    </Helmet>
  )
}

export default MetaHead
