import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'
import TodoForm from 'src/components/Todo/TodoForm'
import { QUERY } from 'src/components/Todo/TodosCell'

const CREATE_TODO_MUTATION = gql`
  mutation CreateTodoMutation($input: CreateTodoInput!) {
    createTodo(input: $input) {
      id
    }
  }
`

const NewTodo = ({ calendarId }) => {
  const inputRef = React.useRef(null)

  const [createTodo, { loading, error }] = useMutation(CREATE_TODO_MUTATION, {
    onCompleted: () => {
      if (inputRef.current) {
        inputRef.current.focus()
      }
      toast.success('Todo created')
    },
    refetchQueries: [{ query: QUERY, variables: { calendarId } }],
    awaitRefetchQueries: true,
  })

  const onSave = (input) => {
    createTodo({ variables: { input } })
  }

  React.useEffect(() => {
    if (error) {
      toast.error(error.message)
    }
  }, [error])

  React.useEffect(() => {
    inputRef.current.focus()
  })

  return (
    <TodoForm
      onSave={onSave}
      loading={loading}
      error={error}
      calendarId={calendarId}
      new={true}
      ref={inputRef}
    />
  )
}

export default NewTodo
