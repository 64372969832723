import { Set, Router, Route } from '@redwoodjs/router'
import HomePage from 'src/pages/HomePage'
import DefaultLayout from 'src/layouts/DefaultLayout'
import CalendarProvider from 'src/components/CalendarProvider'

const Routes = () => {
  return (
    <Router>
      <Set private unauthenticated="home" wrap={[CalendarProvider, DefaultLayout]}>
        <Route path="/tasks" page={TasksPage} name="tasks" />
      </Set>
      <Set wrap={[DefaultLayout]}>
        <Route path="/" page={HomePage} name="home" />
        <Route notfound page={NotFoundPage} />
      </Set>
    </Router>
  )
}

export default Routes
