import { Fragment } from 'react'
import { Form, FieldError, Label, TextField, Submit } from '@redwoodjs/forms'
import { Listbox, Transition } from '@headlessui/react'
import { EditingTodoContext } from 'src/components/CalendarProvider'
import {
  BiPlusCircle,
  BiSave,
  BiXCircle,
  BiChevronDown,
  BiMinusCircle,
  BiCheck,
  BiCheckCircle,
  BiCircle,
} from 'react-icons/bi'

const frequencies = [
  { name: 'Daily', value: 'daily' },
  { name: 'Weekly', value: 'weekly' },
  { name: 'Bi-Weekly', value: 'biweekly' },
  { name: 'Monthly', value: 'monthly' },
  { name: 'Quarterly', value: 'quarterly' },
  { name: 'Annual', value: 'annual' },
]

const TodoForm = React.forwardRef((props, ref) => {
  const { isDisabled } = React.useContext(EditingTodoContext)
  const disabled = props.new && isDisabled ? true : false

  const [title, setTitle] = React.useState(
    props.todo?.title ? props.todo.title : ''
  )
  const [frequency, setFrequency] = React.useState(
    props.todo?.frequency ? props.todo.frequency : frequencies[0].value
  )

  const frequencyName = (value) => {
    const selected = frequencies.filter((f) => f.value === value)
    return selected[0].name
  }

  const onSubmit = async (data) => {
    data.calendarId = props?.todo?.calendarId || props.calendarId
    data.checked = props?.todo?.checked || false
    data.frequency = frequency
    await props.onSave(data, props?.todo?.id)
    if (props.new) setTitle('')
  }

  React.useEffect(() => {
    if (!title && ref.current) {
      ref.current.focus()
    }
  }, [title])

  return (
    <Form
      onSubmit={onSubmit}
      error={props.error}
      className={`${
        !props.new ? 'border-t border-gray-300 ' : ''
      }flex items-stretch ${!disabled ? 'bg-white' : 'bg-gray-50'}`}
    >
      {!props.new && (
        <div className="py-2 pl-2 flex items-center">
          {props?.todo?.checked ? (
            <>
              <BiCheckCircle
                className={`${
                  !isDisabled ? 'text-green-600' : 'text-gray-400'
                } w-6 h-6 flex-shrink-0`}
                aria-hidden="true"
              />
            </>
          ) : (
            <>
              <BiCircle
                className="w-6 h-6 text-gray-400 flex-shrink-0"
                aria-hidden="true"
              />
            </>
          )}
        </div>
      )}

      <Label name="title" className="sr-only">
        Title
      </Label>
      <TextField
        name="title"
        value={title}
        placeholder={props.new ? 'New task...' : 'Task...'}
        className="flex-1 p-2 border-0 rounded-none focus:ring-2"
        errorClassName="flex-1 p-2 border-0 rounded-none focus:ring-2"
        autoComplete="off"
        disabled={disabled}
        onChange={(e) => setTitle(e.target.value)}
        validation={{ required: true }}
        ref={ref}
      />
      <FieldError name="title" className="sr-only" />

      {!disabled && (
        <Listbox value={frequency} onChange={setFrequency}>
          <div className="relative border-l border-gray-300 flex items-stretch">
            <Listbox.Label className="sr-only">Frequency</Listbox.Label>
            <Listbox.Button className="p-2 text-sm w-32 text-left font-semibold">
              <span className="block">{frequencyName(frequency)}</span>
              <span className="absolute inset-y-0 right-0 flex items-center pr-1 pointer-events-none">
                <BiChevronDown className="w-5 h-5" aria-hidden="true" />
              </span>
            </Listbox.Button>
            <Transition
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options
                className="absolute top-full overflow-auto text-sm bg-white text-gray-900 shadow-lg max-h-60 border border-t-0 border-gray-300 focus:outline-none"
                style={{ width: 'calc(8rem + 2px)', marginLeft: '-1px' }}
              >
                {frequencies.map((item, i) => (
                  <Listbox.Option
                    key={i}
                    value={item.value}
                    className={({ active }) =>
                      `${
                        active ? 'bg-blue-100 text-blue-600 ' : ''
                      }cursor-pointer px-2 py-1 font-semibold flex items-center`
                    }
                  >
                    {({ selected }) => (
                      <>
                        <span className="flex-1">{item.name}</span>
                        {selected && <BiCheck />}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </Listbox>
      )}

      <div className="flex items-center justify-center border-l border-gray-300 px-2">
        <Submit
          disabled={props.loading || disabled}
          className={`${
            props.new
              ? `${
                  !disabled
                    ? 'text-green-600 hover:text-green-700'
                    : 'text-gray-400 cursor-default'
                }`
              : 'text-blue-600 hover:text-blue-700'
          } flex items-center p-1 text-xs font-semibold rounded-lg transition-colors duration-300`}
          title={props.new ? 'Add Task' : 'Save Task'}
        >
          {props.new ? (
            <>
              <BiPlusCircle className="w-4 h-4" />
              <span className="sr-only">Add Task</span>
            </>
          ) : (
            <>
              <BiSave className="w-4 h-4" />
              <span className="sr-only">Save Task</span>
            </>
          )}
        </Submit>
        <button
          title={props.new ? 'Clear Task' : 'Cancel'}
          type="button"
          disabled={props.loading || disabled}
          onClick={() => {
            if (props.new) {
              setTitle('')
            } else {
              props.setIsEditing(false)
            }
          }}
          className={`${
            !disabled
              ? 'text-gray-600 hover:text-gray-700'
              : 'text-gray-400 cursor-default'
          } flex items-center p-1 text-xs font-semibold rounded-lg transition-colors duration-300`}
        >
          {props.new ? (
            <BiMinusCircle className="w-4 h-4" />
          ) : (
            <BiXCircle className="w-4 h-4" />
          )}
          <span className="sr-only">{props.new ? 'Clear Task' : 'Cancel'}</span>
        </button>
      </div>
    </Form>
  )
})

export default TodoForm
