import { FieldError, Label, SelectField } from '@redwoodjs/forms'
import { timeZonesNames } from '@vvo/tzdb'

const TimezoneSelect = ({ name, defaultValue, required }) => {
  return (
    <>
      <Label
        name={name}
        className="rw-label"
        errorClassName="rw-label rw-label-error"
      >
        Timezone
      </Label>
      <SelectField
        name={name}
        defaultValue={defaultValue}
        className="rw-input"
        errorClassName="rw-input rw-input-error"
        validation={{ required }}
      >
        {timeZonesNames.map((timezone, i) => (
          <option key={i} value={timezone}>
            {timezone}
          </option>
        ))}
      </SelectField>
      <FieldError name={name} className="rw-field-error" />
    </>
  )
}

export default TimezoneSelect
