import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { BiX } from 'react-icons/bi'

type DefaultModalProps = {
  title?: string
  description?: string
  isOpen: boolean
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  color?: string
  children?: React.ReactNode
}

const Modal = ({
  title,
  description,
  isOpen,
  setIsOpen,
  color = 'gray',
  children,
}: DefaultModalProps) => {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        onClose={() => setIsOpen(false)}
      >
        <div className="min-h-screen">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay
              className={`fixed inset-0 bg-${color}-500 bg-opacity-95`}
            />
          </Transition.Child>

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="min-h-screen w-full flex items-center justify-center">
              <div className="inline-block w-full max-w-sm p-6 mb-12 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <div className="flex items-start">
                  {title && (
                    <Dialog.Title className="font-bold text-lg">
                      {title}
                    </Dialog.Title>
                  )}
                  <button
                    className="ml-auto p-1 rounded-full bg-gray-200 transition-colors duration-300 hover:bg-gray-300"
                    onClick={() => setIsOpen(false)}
                  >
                    <BiX className="h-5 w-5" />
                    <span className="sr-only">Close modal</span>
                  </button>
                </div>
                {description && (
                  <Dialog.Description>{description}</Dialog.Description>
                )}
                <div className="mt-2">{children}</div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  )
}

export default Modal
