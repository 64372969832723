import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'
import { QUERY } from 'src/components/Todo/TodosCell'
import TodoItem from 'src/components/TodoItem'
import Modal from 'src/components/Modal'
import MetaHead from 'src/components/MetaHead'
import { EditingTodoContext } from 'src/components/CalendarProvider'
import { Disclosure, Transition } from '@headlessui/react'
import { BiMinusCircle } from 'react-icons/bi'

const TodosList = ({ todos, calendarId, display = false }) => {
  const organizeTodos = (frequency: string) => {
    const filtered = todos.filter((todo) => todo.frequency === frequency)

    filtered.sort((a, b) => (a.checked === b.checked ? 0 : a.checked ? 1 : -1))

    return filtered
  }

  const dailyTodos = organizeTodos('daily')
  const weeklyTodos = organizeTodos('weekly')
  const biweeklyTodos = organizeTodos('biweekly')
  const monthlyTodos = organizeTodos('monthly')
  const quarterlyTodos = organizeTodos('quarterly')
  const annualTodos = organizeTodos('annual')

  return (
    <>
      <TodosSection
        todos={dailyTodos}
        title="Today"
        frequency="daily"
        calendarId={calendarId}
        display={display}
      />
      <TodosSection
        todos={weeklyTodos}
        title="This Week"
        frequency="weekly"
        calendarId={calendarId}
        display={display}
      />
      <TodosSection
        todos={biweeklyTodos}
        title="This Week or Next"
        frequency="biweekly"
        calendarId={calendarId}
        display={display}
      />
      <TodosSection
        todos={monthlyTodos}
        title="This Month"
        frequency="monthly"
        calendarId={calendarId}
        display={display}
      />
      <TodosSection
        todos={quarterlyTodos}
        title="This Quarter"
        frequency="quarterly"
        calendarId={calendarId}
        display={display}
      />
      <TodosSection
        todos={annualTodos}
        title="This Year"
        frequency="annual"
        calendarId={calendarId}
        display={display}
      />
    </>
  )
}

const CLEAR_TODOS_MUTATION = gql`
  mutation ClearTodosMutation($calendarId: String!, $frequency: Frequency!) {
    clearTodos(calendarId: $calendarId, frequency: $frequency)
  }
`

const TodosSection = ({ todos, title, frequency, calendarId, display }) => {
  const { isDisabled, setIsDisabled } = React.useContext(EditingTodoContext)
  const [isRefreshOpen, setIsRefreshOpen] = React.useState(false)

  const [clearTodos, { loading, error }] = useMutation(CLEAR_TODOS_MUTATION, {
    onCompleted: () => {
      setIsRefreshOpen(false)
      toast.success('Tasks cleared')
    },
    refetchQueries: [{ query: QUERY, variables: { calendarId } }],
    awaitRefetchQueries: true,
  })

  const checked = todos.filter((todo) => todo.checked === true)

  const onClearTodos = () => {
    clearTodos({ variables: { calendarId, frequency } })
  }

  const initiateClear = () => {
    setIsRefreshOpen(true)
  }

  React.useEffect(() => {
    if (error) {
      toast.error(error.message)
    }
  }, [error])

  return (
    <>
      {todos.length > 0 && (
        <Disclosure defaultOpen={true}>
          {({ open }) => (
            <>
              <div className="w-full flex items-center justify-between font-bold bg-gray-100 text-gray-900 border-t-2 border-gray-600">
                <Disclosure.Button
                  className="flex-1 flex items-center text-left font-bold px-3 py-2"
                  disabled={display || isDisabled}
                >
                  <h2>{title}</h2>
                  <span className="ml-2 text-xs font-semibold">{`(${checked.length} of ${todos.length})`}</span>
                </Disclosure.Button>
                {checked.length > 0 && !isDisabled && (
                  <nav className="p-2">
                    <button
                      onClick={initiateClear}
                      disabled={loading || display}
                      className="flex items-center text-gray-600 hover:text-gray-700 p-1 rounded-lg transition-colors duration-300"
                    >
                      <BiMinusCircle className="w-4 h-4" />
                      <span className="sr-only">Uncheck tasks for {title}</span>
                    </button>
                    <Modal
                      title="Uncheck tasks"
                      isOpen={isRefreshOpen}
                      setIsOpen={setIsRefreshOpen}
                    >
                      <MetaHead title="Uncheck tasks" />
                      <p>
                        Are your sure you want to uncheck all tasks for{' '}
                        <em className="lowercase">{title}</em>?
                      </p>
                      <div className="btn-group">
                        <button
                          onClick={() => setIsRefreshOpen(false)}
                          className="btn btn-gray"
                        >
                          Cancel
                        </button>
                        <button onClick={onClearTodos} className="btn btn-blue">
                          Uncheck
                        </button>
                      </div>
                    </Modal>
                  </nav>
                )}
              </div>
              <Transition
                enter="transition duration-100 ease-out"
                enterFrom="transform scale-95 opacity-0"
                enterTo="transform scale-100 opacity-100"
                leave="transition duration-75 ease-out"
                leaveFrom="transform scale-100 opacity-100"
                leaveTo="transform scale-95 opacity-0"
              >
                <Disclosure.Panel>
                  <ul>
                    {todos.map((todo, i) => (
                      <TodoItem
                        key={i}
                        todo={todo}
                        calendarId={calendarId}
                        isDisabled={isDisabled}
                        setIsDisabled={setIsDisabled}
                        display={display}
                      />
                    ))}
                  </ul>
                </Disclosure.Panel>
              </Transition>
            </>
          )}
        </Disclosure>
      )}
    </>
  )
}

export default TodosList
