import { useAuth } from '@redwoodjs/auth'
import { Link, routes } from '@redwoodjs/router'
import { Toaster } from '@redwoodjs/web/toast'
import { SkipNavLink, SkipNavContent } from '@redwoodjs/router'
import MetaHead from 'src/components/MetaHead'
import { SubscriptionContext } from 'src/components/CalendarProvider'
import LoginButton from 'src/components/LoginButton'
import Modal from 'src/components/Modal'
import EditUserCell from 'src/components/User/EditUserCell'
import ArchivedCalendarsCell from 'src/components/ArchivedCalendarsCell'
import Subscription from 'src/components/Subscription'
import Logo from 'src/lib/logo.svg'
import { BiLogOut, BiUserCircle, BiLock, BiLockOpen } from 'react-icons/bi'
import { VscTwitter } from 'react-icons/vsc'

import '@reach/skip-nav/styles.css'

type DefaultLayoutProps = {
  children?: React.ReactNode
  className?: string
}

const socialNav = [
  { name: 'Twitter', icon: VscTwitter, to: 'https://twitter.com/reoccur_io' },
]

const DefaultLayout = ({ children }: DefaultLayoutProps) => {
  const { isAuthenticated, logOut, currentUser } = useAuth()

  const { isSubscribed } = React.useContext(SubscriptionContext)

  const [isUserOpen, setIsUserOpen] = React.useState(false)
  const [isSubscriptionOpen, setIsSubscriptionOpen] = React.useState(false)
  const [isLogoutOpen, setIsLogoutOpen] = React.useState(false)

  return (
    <div
      className={`${
        !isAuthenticated ? 'bg-gray-50 ' : ''
      }min-h-screen flex flex-col`}
    >
      <MetaHead />
      <SkipNavLink contentId="main" />
      <Toaster />
      <header className="py-1 px-4 md:px-0">
        <Wrapper className="flex items-center justify-between">
          <Link
            to={routes.home()}
            className="font-bold text-lg flex items-center transition-colors duration-300 hover:text-blue-600"
          >
            <Logo className="fill-current w-auto h-5 mr-1.5 transform -translate-y-px" />
            <span>ReOccur</span>
          </Link>
          {!isAuthenticated ? (
            <LoginButton />
          ) : (
            <nav className="flex items-center text-xl text-gray-600">
              <button
                onClick={() => setIsSubscriptionOpen(true)}
                title="Subscription"
                className={`${
                  !isSubscribed ? 'btn-blue' : 'btn-green'
                } btn flex items-center text-xs mr-2 px-2 py-1`}
              >
                {isSubscribed ? <BiLockOpen className="h-4 w-4" /> : <BiLock />}
                <span className="ml-1">{isSubscribed ? 'Pro' : 'Upgrade'}</span>
              </button>
              <Modal
                title={`Subscription`}
                isOpen={isSubscriptionOpen}
                setIsOpen={setIsSubscriptionOpen}
              >
                <Subscription setIsSubscriptionOpen={setIsSubscriptionOpen} />
              </Modal>
              <button
                onClick={() => setIsUserOpen(true)}
                title="User settings"
                className="p-1 rounded-lg"
              >
                <BiUserCircle />
                <span className="sr-only">User settings</span>
              </button>
              <Modal
                title={`Update settings for ${currentUser.email}`}
                isOpen={isUserOpen}
                setIsOpen={setIsUserOpen}
              >
                <MetaHead title="Edit user settings" />
                <EditUserCell
                  id={currentUser.id}
                  setIsUserOpen={setIsUserOpen}
                />
              </Modal>
              <ArchivedCalendarsCell />
              <button
                onClick={() => setIsLogoutOpen(true)}
                title="Log out"
                className="p-1 rounded-lg"
              >
                <BiLogOut />
                <span className="sr-only">Log out</span>
              </button>
              <Modal
                title="Log out"
                description="Are you sure you want to log out?"
                isOpen={isLogoutOpen}
                setIsOpen={setIsLogoutOpen}
              >
                <MetaHead title="Log out" />
                <div className="btn-group">
                  <button
                    onClick={() => setIsLogoutOpen(false)}
                    className="btn btn-gray"
                  >
                    Cancel
                  </button>
                  <button onClick={logOut} className="btn btn-blue">
                    Log out
                  </button>
                </div>
              </Modal>
            </nav>
          )}
        </Wrapper>
      </header>
      <SkipNavContent id="main" />
      <main className="flex-1 w-full overflow-x-hidden">
        <Wrapper className="md:py-4">{children}</Wrapper>
      </main>
      <footer className="text-gray-500 my-1">
        <Wrapper>
          <nav className="flex items-center justify-center">
            {socialNav.map((item, i) => (
              <a
                href={item.to}
                key={i}
                className="transition-colors duration-300 hover:text-blue-600"
                target="_blank"
                rel="noreferrer"
              >
                <item.icon className="h-5 w-5" />
                <span className="sr-only">{item.name}</span>
              </a>
            ))}
          </nav>
          <p className="text-center text-xs mt-1">
            &copy; {new Date().getFullYear()}{' '}
            <Link
              to={routes.home()}
              className="transition-colors duration-300 hover:text-blue-600"
            >
              ReOccur
            </Link>
            . All rights reserved.
          </p>
        </Wrapper>
      </footer>
    </div>
  )
}

export const Wrapper = ({ children, className }: DefaultLayoutProps) => {
  const { isAuthenticated } = useAuth()
  return (
    <div
      className={`${className ? className + ' ' : ''}${
        isAuthenticated ? 'md:max-w-2xl ' : ''
      }w-full mx-auto md:px-8`}
    >
      {children}
    </div>
  )
}

export default DefaultLayout
