import { useAuth } from '@redwoodjs/auth'
import { useLocation } from '@redwoodjs/router'
import { Form, Label, TextField, CheckboxField, Submit } from '@redwoodjs/forms'
import Modal from 'src/components/Modal'
import {
  IoLogoGoogle,
  IoLogoApple,
  IoLogoFacebook,
  IoLogoGithub,
  IoLogoTwitter,
  IoLogoWindows,
} from 'react-icons/io'

const logins = [
  // { name: 'Apple', provider: 'apple', icon: IoLogoApple },
  { name: 'Google', provider: 'google', icon: IoLogoGoogle },
  // { name: 'Azure', provider: 'azure', icon: IoLogoWindows },
  { name: 'Facebook', provider: 'facebook', icon: IoLogoFacebook },
  { name: 'GitHub', provider: 'github', icon: IoLogoGithub },
  // { name: 'Twitter', provider: 'twitter', icon: IoLogoTwitter },
]

const LoginButton = ({ variant = 'dark', large = false, plan = undefined }) => {
  const { logIn } = useAuth()
  const { pathname } = useLocation()
  const [isOpen, setIsOpen] = React.useState(false)

  const defaultEmail =
    typeof window !== 'undefined'
      ? localStorage.getItem('userEmail')
      : undefined

  let redirectTo = process.env.URL
    ? process.env.URL + pathname
    : 'http://localhost:8910' + pathname

  if (plan) {
    redirectTo += `?plan=${plan}`
  }

  const onSubmit = async (data) => {
    if (typeof window !== 'undefined') {
      if (data.rememberMe) {
        localStorage.setItem('userEmail', data.email)
      } else if (defaultEmail) {
        localStorage.removeItem('userEmail')
      }
    }

    if (data.email.length) {
      try {
        await logIn({
          email: data.email,
          redirectTo,
        })
        setIsOpen(false)
      } catch (e) {
        console.log(e)
      }
    }
  }

  const providerLogin = async (provider) => {
    if (provider) {
      try {
        await logIn({
          provider,
          redirectTo,
        })
        setIsOpen(false)
      } catch (e) {
        console.log(e)
      }
    }
  }

  return (
    <>
      <button
        className={`${
          variant === 'light'
            ? 'bg-gray-100 text-gray-900 hover:bg-gray-200 focus:ring-gray-100'
            : 'bg-blue-600 text-gray-100 hover:bg-blue-800 focus:ring-blue-600'
        } ${
          large ? 'text-lg px-4 py-3' : 'text-sm px-3 py-2'
        } rounded-full flex items-center justify-center font-bold transition-colors duration-300 focus:outline-none focus:ring-2 focus:ring-offset-2`}
        onClick={() => setIsOpen(true)}
      >
        <span>Login or Sign up</span>
      </button>
      <Modal title="Login or Sign Up" isOpen={isOpen} setIsOpen={setIsOpen}>
        <Form onSubmit={onSubmit} className="space-y-3 mt-4 text-sm">
          <Label name="email" className="sr-only" />
          <TextField
            name="email"
            defaultValue={defaultEmail}
            placeholder="Email address"
            validation={{ required: true }}
          />
          <div className="flex items-center">
            <CheckboxField
              name="rememberMe"
              type="checkbox"
              defaultChecked={defaultEmail ? true : false}
              className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
            />
            <Label
              name="rememberMe"
              className="ml-2 block text-sm text-gray-500"
            >
              Remember my email
            </Label>
          </div>
          <Submit className="btn btn-blue w-full">
            Send login link to email
          </Submit>
        </Form>
        <div className="relative my-6">
          <div className="absolute inset-0 flex items-center">
            <div className="w-full border-t border-gray-300" />
          </div>
          <div className="relative flex justify-center text-xs">
            <span className="px-2 bg-white text-gray-500">
              or continue with
            </span>
          </div>
        </div>
        <div className="grid grid-cols-3 gap-3">
          {logins.map((login, i) => (
            <button
              key={i}
              className="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md bg-white text-xl font-medium text-gray-500 transition-colors duration-300 hover:bg-gray-100"
              title={login.name}
              onClick={() => providerLogin(login.provider)}
            >
              <login.icon />
              <span className="sr-only">{login.name}</span>
            </button>
          ))}
        </div>
      </Modal>
    </>
  )
}

export default LoginButton
