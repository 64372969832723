import Todos from 'src/components/Todo/Todos'
import LoginButton from 'src/components/LoginButton'
import { BiCheck } from 'react-icons/bi'

const todos = [
  { title: 'Water plants', frequency: 'daily', checked: false },
  { title: 'Clean dishes', frequency: 'daily', checked: true },
  { title: 'Grocery shopping', frequency: 'weekly', checked: false },
  { title: 'Laundry', frequency: 'weekly', checked: true },
  { title: 'Trash', frequency: 'weekly', checked: true },
  { title: 'Vacuum', frequency: 'biweekly', checked: false },
  { title: 'Change air filter', frequency: 'monthly', checked: false },
  { title: 'Pay home insurance', frequency: 'annual', checked: true },
]

const features = [
  {
    name: 'Create tasks',
    description:
      "Create list of tasks so you don't have to keep them in your head.",
  },
  {
    name: 'Change frequencies',
    description:
      'Set your task to reoccur every day, week, other week, month, quarter, or year.',
  },
  {
    name: 'Multiple calendars',
    description: 'Create multiple calendars to manage different sets of tasks.',
  },
  {
    name: 'Share calendars',
    description:
      'Share calendars with family and friends so you can keep track together.',
  },
  {
    name: 'Auto-clearing tasks',
    description:
      "Tasks automatically clear after the set frequency has elapsed, so you know it's time to do it again.",
  },
  {
    name: 'Easy editing',
    description:
      'Easily check, uncheck, edit or delete tasks in a single easy to use interface',
  },
]

const tiers = [
  {
    name: 'Basic',
    priceMonthly: 0,
    description: 'For a couple small lists',
    features: ['2 calendars', '10 tasks per calendar'],
  },
  {
    name: 'Pro',
    priceMonthly: 5,
    description: 'For many large lists',
    features: ['Unlimited calendars', 'Unlimited tasks per calendar'],
    plan: 'pro',
  },
]

const MarketingHome = () => {
  return (
    <div className="md:-mx-8 md:-my-4">
      <section className="bg-blue-600">
        <div className="py-16 mx-auto max-w-7xl px-4 sm:py-24">
          <div className="text-center">
            <h1 className="text-4xl tracking-tight font-extrabold text-white sm:text-5xl md:text-6xl">
              <span className="block xl:inline">Stop keeping your</span>{' '}
              <span className="block text-blue-200 xl:inline">todo list</span>{' '}
              <span className="block xl:inline">in your head.</span>
            </h1>
            <p className="mt-3 max-w-md mx-auto text-base text-blue-100 sm:text-lg md:mt-5 md:text-xl md:max-w-2xl">
              Manage your reoccurring tasks in one place with lists that
              automatically update when tasks are due.
            </p>
            <div className="mt-5 max-w-md mx-auto flex justify-center md:mt-8">
              <LoginButton variant="light" large={true} />
            </div>
          </div>
        </div>
      </section>
      <div className="py-16 bg-gray-50 overflow-hidden lg:py-24">
        <div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
          <div className="relative lg:grid lg:grid-cols-2 lg:gap-12 lg:items-center">
            <div className="relative">
              <h2 className="text-2xl font-extrabold text-gray-900 tracking-tight sm:text-3xl">
                All-in-one platform
              </h2>
              <p className="mt-3 text-lg text-gray-500">
                {
                  "Let ReOccur's stellar features keep track of your lists so you don't have to."
                }
              </p>

              <dl className="mt-10 space-y-6">
                {features.map((item, i) => (
                  <div key={i} className="relative">
                    <dt>
                      <BiCheck
                        className="absolute h-6 w-6 text-green-500"
                        aria-hidden="true"
                      />
                      <p className="ml-9 text-lg leading-6 font-medium text-gray-900">
                        {item.name}
                      </p>
                    </dt>
                    <dd className="mt-2 ml-9 text-base text-gray-500">
                      {item.description}
                    </dd>
                  </div>
                ))}
              </dl>
            </div>

            <div className="mt-10 -mx-4 relative lg:mt-0" aria-hidden="true">
              <div className="flex items-center justify-between bg-gray-600 sm:rounded-tl-xl sm:rounded-tr-xl px-3 py-2 text-gray-50">
                <span className="text-lg font-bold pl-3">Spencer Family</span>
              </div>
              <div className="sm:border-r-2 sm:border-l-2 border-b-2 sm:rounded-bl-xl sm:rounded-br-xl overflow-hidden border-gray-600 pointer-events-none">
                <Todos todos={todos} display={true} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-blue-600">
        <div className="pt-12 sm:pt-16 lg:pt-24">
          <div className="max-w-7xl mx-auto text-center px-4 sm:px-6 lg:px-8">
            <div className="max-w-3xl mx-auto space-y-2 lg:max-w-none">
              <h2 className="text-lg leading-6 font-semibold text-blue-100 uppercase tracking-wider">
                Pricing
              </h2>
              <p className="text-3xl font-extrabold text-white sm:text-4xl lg:text-5xl">
                The right plan for your todos
              </p>
              <p className="text-xl text-blue-100">
                Got a small list or two? Start for free! Looking for longer
                lists? Upgrade to the pro plan.
              </p>
            </div>
          </div>
        </div>
        <div className="mt-8 pb-12 sm:mt-12 sm:pb-16 lg:mt-16 lg:pb-24">
          <div className="relative">
            <div className="relative z-10 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className="max-w-md mx-auto space-y-4 lg:max-w-5xl lg:grid lg:grid-cols-2 lg:gap-5 lg:space-y-0">
                {tiers.map((tier) => (
                  <div
                    key={tier.name}
                    className="flex flex-col rounded-lg shadow-lg overflow-hidden"
                  >
                    <div className="px-6 py-8 bg-white sm:p-10 sm:pb-6">
                      <div>
                        <h3
                          className="inline-flex px-4 py-1 rounded-full text-sm font-semibold tracking-wide uppercase bg-blue-100 text-blue-600"
                          id="tier-standard"
                        >
                          {tier.name}
                        </h3>
                      </div>
                      <div className="mt-4 flex items-baseline text-6xl font-extrabold">
                        ${tier.priceMonthly}
                        <span className="ml-1 text-2xl font-medium text-gray-500">
                          /mo
                        </span>
                      </div>
                      <p className="mt-5 text-lg text-gray-500">
                        {tier.description}
                      </p>
                    </div>
                    <div className="flex-1 flex flex-col justify-between px-6 pt-6 pb-8 bg-gray-50 space-y-6 sm:p-10 sm:pt-6">
                      <ul className="space-y-4">
                        {tier.features.map((feature) => (
                          <li key={feature} className="flex items-start">
                            <div className="flex-shrink-0">
                              <BiCheck
                                className="h-6 w-6 text-green-500"
                                aria-hidden="true"
                              />
                            </div>
                            <p className="ml-3 text-base text-gray-700">
                              {feature}
                            </p>
                          </li>
                        ))}
                      </ul>
                      <LoginButton large={true} plan={tier.plan} />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <section>
        <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-24 lg:px-8 lg:flex lg:items-center lg:justify-between">
          <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 md:text-4xl">
            <span className="block">Ready to get organized?</span>
            <span className="block text-blue-600">Sign up for free.</span>
          </h2>
          <div className="mt-8 flex lg:mt-0 lg:flex-shrink-0">
            <LoginButton large={true} />
          </div>
        </div>
      </section>
    </div>
  )
}

export default MarketingHome
