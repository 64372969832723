import type { FindTodos } from 'types/graphql'
import type { CellSuccessProps, CellFailureProps } from '@redwoodjs/web'

import Todos from 'src/components/Todo/Todos'
import NewTodo from 'src/components/Todo/NewTodo'

import { SubscriptionContext } from 'src/components/CalendarProvider'

export const QUERY = gql`
  query FindTodos($calendarId: String!) {
    todos(calendarId: $calendarId) {
      id
      title
      frequency
      checked
    }
  }
`

export const Loading = () => <></>

export const Success = ({ todos, calendarId }: CellSuccessProps<FindTodos>) => {
  const { isSubscribed } = React.useContext(SubscriptionContext)

  const canAddNew = isSubscribed ? true : todos.length < 10 ? true : false

  return (
    <>
      {canAddNew && <NewTodo calendarId={calendarId} />}
      <Todos todos={todos} calendarId={calendarId} />
    </>
  )
}
