import type { CalendarsQuery } from 'types/graphql'
import type { CellSuccessProps, CellFailureProps } from '@redwoodjs/web'
import {
  CalendarsContext,
  SharedCalendarsContext,
} from 'src/components/CalendarProvider'

import NewCalendar from 'src/components/Calendar/NewCalendar'

export const QUERY = gql`
  query CalendarsQuery {
    userCalendars: calendars {
      calendars {
        id
        userId
        name
        sharedWith {
          id
          name
          email
        }
      }
      sharedCalendars {
        id
        userId
        name
      }
    }
  }
`

export const Loading = () => <div>Loading...</div>

export const Empty = () => {
  const [, setIsOpen] = React.useState(true)
  return (
    <div>
      <h1 className="font-bold text-lg mb-1">Create First Calendar</h1>
      <NewCalendar setNewCalendarOpen={setIsOpen} />
    </div>
  )
}

export const Failure = ({ error }: CellFailureProps) => (
  <div style={{ color: 'red' }}>Error: {error.message}</div>
)

export const Success = ({
  userCalendars,
}: CellSuccessProps<CalendarsQuery>) => {
  const { setCalendars } = React.useContext(CalendarsContext)
  const { setSharedCalendars } = React.useContext(SharedCalendarsContext)

  React.useEffect(() => {
    setCalendars(userCalendars.calendars)
    setSharedCalendars(userCalendars.sharedCalendars)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userCalendars])

  return <></>
}
