import { useAuth } from '@redwoodjs/auth'
import { Redirect, routes } from '@redwoodjs/router'
import React from 'react'
import MarketingHome from 'src/components/MarketingHome'

const HomePage = () => {
  const { isAuthenticated } = useAuth()

  if (isAuthenticated) {
    return <Redirect to={routes.tasks()} />
  }

  return <MarketingHome />
}

export default HomePage
