import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'
import MetaHead from 'src/components/MetaHead'
import { QUERY } from 'src/components/Todo/TodosCell'
import TodoForm from 'src/components/Todo/TodoForm'
import Modal from 'src/components/Modal'
import {
  EditingTodoContext,
  ViewCompletedContext,
} from 'src/components/CalendarProvider'
import { BiEdit, BiTrash, BiCheckCircle, BiCircle } from 'react-icons/bi'

const UPDATE_TODO_MUTATION = gql`
  mutation UpdateTodoMutation(
    $id: String!
    $calendarId: String!
    $input: UpdateTodoInput!
  ) {
    updateTodo(id: $id, calendarId: $calendarId, input: $input) {
      id
      title
      calendarId
      frequency
      checked
    }
  }
`

const TOGGLE_TODO_MUTATION = gql`
  mutation ToggleTodoMutation(
    $id: String!
    $checked: Boolean!
    $calendarId: String!
  ) {
    toggleTodo(id: $id, checked: $checked, calendarId: $calendarId) {
      id
      title
      calendarId
      frequency
      checked
    }
  }
`

const DELETE_TODO_MUTATION = gql`
  mutation DeleteTodoMutation($id: String!, $calendarId: String!) {
    deleteTodo(id: $id, calendarId: $calendarId) {
      id
    }
  }
`

const TodoItem = ({ todo, calendarId, last = false, display }) => {
  const { isDisabled, setIsDisabled } = React.useContext(EditingTodoContext)
  const { viewCompleted } = React.useContext(ViewCompletedContext)
  const [isEditing, setIsEditing] = React.useState(false)
  const [deleteTodoOpen, setDeleteTodoOpen] = React.useState(false)
  const inputRef = React.useRef(null)

  const showTodo = !viewCompleted && todo.checked ? false : true

  React.useEffect(() => {
    if (isEditing) {
      setIsDisabled(true)
      inputRef.current.focus()
    } else {
      setIsDisabled(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditing])

  const [toggleTodo, { loading: toggleLoading, error: toggleError }] =
    useMutation(TOGGLE_TODO_MUTATION, {
      onCompleted: (data) => {
        if (data.toggleTodo.checked) {
          toast.success('Task completed!')
        }
      },
    })

  const [updateTodo, { loading, error }] = useMutation(UPDATE_TODO_MUTATION, {
    onCompleted: () => {
      toast.success('Task updated')
      setIsEditing(false)
      setIsDisabled(false)
    },
  })

  const onToggle = () => {
    toggleTodo({
      variables: {
        id: todo.id,
        checked: !todo.checked,
        calendarId,
      },
    })
  }

  const onSave = (input, id) => {
    updateTodo({ variables: { id, calendarId, input } })
  }

  const [deleteTodo, { loading: deleteLoading, error: deleteError }] =
    useMutation(DELETE_TODO_MUTATION, {
      onCompleted: () => {
        toast.success('Task deleted')
      },
      refetchQueries: [{ query: QUERY, variables: { calendarId } }],
      awaitRefetchQueries: true,
    })

  const onDeleteClick = () => {
    setDeleteTodoOpen(false)
    deleteTodo({ variables: { id: todo.id, calendarId } })
  }

  React.useEffect(() => {
    if (error) {
      toast.error(error.message)
    }

    if (toggleError) {
      toast.error(toggleError.message)
    }

    if (deleteError) {
      toast.error(deleteError.message)
    }
  }, [error, toggleError, deleteError])
  return (
    <>
      {showTodo && (
        <>
          {isEditing ? (
            <>
              <MetaHead title="Edit task" />
              <TodoForm
                todo={todo}
                onSave={onSave}
                error={error}
                loading={loading}
                setIsEditing={setIsEditing}
                ref={inputRef}
              />
            </>
          ) : (
            <li
              className={`${
                !last ? 'border-t ' : ''
              }flex items-stretch border-gray-300 ${
                !isDisabled ? 'bg-white' : 'bg-gray-50'
              }`}
            >
              <h3 className="flex-1">
                <button
                  className={`${
                    !isDisabled ? 'cursor-pointer' : 'cursor-default'
                  } p-2 w-full flex items-center`}
                  onClick={onToggle}
                  disabled={toggleLoading || isDisabled || display}
                >
                  {todo.checked ? (
                    <>
                      <BiCheckCircle
                        className={`${
                          !isDisabled ? 'text-green-600' : 'text-gray-400'
                        } w-6 h-6 flex-shrink-0`}
                        aria-hidden="true"
                      />
                    </>
                  ) : (
                    <>
                      <BiCircle
                        className="w-6 h-6 text-gray-400 flex-shrink-0"
                        aria-hidden="true"
                      />
                    </>
                  )}
                  <span
                    className={`${
                      !isDisabled ? 'text-gray-900' : 'text-gray-400'
                    } ml-2 text-left`}
                  >
                    {todo.title}
                  </span>
                </button>
              </h3>
              <nav className="flex items-center justify-center border-l border-gray-300 px-2">
                <button
                  title={'Edit task'}
                  onClick={() => setIsEditing(true)}
                  disabled={isDisabled || display}
                  className={`${
                    !isDisabled
                      ? 'text-blue-600 hover:text-blue-700'
                      : 'text-gray-400 cursor-default'
                  } flex items-center p-1 text-xs font-semibold rounded-lg transition-colors duration-300`}
                >
                  <BiEdit className="w-4 h-4" />
                  <span className="sr-only">Edit Task</span>
                </button>
                <button
                  title={'Delete task'}
                  disabled={deleteLoading || isDisabled || display}
                  className={`${
                    !isDisabled
                      ? 'text-red-600 hover:text-red-700'
                      : 'text-gray-400 cursor-default'
                  } flex items-center p-1 text-xs font-semibold rounded-lg transition-colors duration-300`}
                  onClick={() => setDeleteTodoOpen(true)}
                >
                  <BiTrash className="w-4 h-4" />
                  <span className="sr-only">Delete Task</span>
                </button>
                <Modal
                  title="Delete Task"
                  isOpen={deleteTodoOpen}
                  setIsOpen={setDeleteTodoOpen}
                  color="red"
                >
                  <MetaHead title="Delete task" />
                  <p>
                    Are your sure you want to Delete <em>{todo.title}</em>?
                  </p>
                  <div className="btn-group">
                    <button
                      onClick={() => setDeleteTodoOpen(false)}
                      className="btn btn-gray"
                    >
                      Cancel
                    </button>
                    <button onClick={onDeleteClick} className="btn btn-red">
                      Delete
                    </button>
                  </div>
                </Modal>
              </nav>
            </li>
          )}
        </>
      )}
    </>
  )
}

export default TodoItem
