import { useMutation } from '@redwoodjs/web'
import { loadStripe } from '@stripe/stripe-js'
import { Switch } from '@headlessui/react'
import { SubscriptionContext } from 'src/components/CalendarProvider'
import { BiCheck } from 'react-icons/bi'

const stripePromise = loadStripe(process.env.STRIPE_KEY)

const CREATE_SESSION_MUTATION = gql`
  mutation CreateSessionMutation($priceId: String!) {
    createSession(priceId: $priceId) {
      id
    }
  }
`

const CREATE_BILLING_SESSION_MUTATION = gql`
  mutation CreateBillingSessionMutation {
    billingSession {
      id
      url
    }
  }
`

const plans = [
  {
    name: 'Pro Plan',
    monthly: 5,
    annual: 50,
    features: ['Unlimited calendars', 'Unlimited tasks'],
  },
  {
    name: 'Basic Plan',
    free: true,
    features: ['2 calendars', '10 tasks per calendar'],
  },
]

const Subscription = () => {
  const [createSession, { data, loading }] = useMutation(
    CREATE_SESSION_MUTATION
  )

  const [billingSession, { data: billingData, loading: billingLoading }] =
    useMutation(CREATE_BILLING_SESSION_MUTATION)

  const [isAnnual, setIsAnnual] = React.useState(false)
  const { isSubscribed } = React.useContext(SubscriptionContext)

  const checkoutRedirect = async (sessionId) => {
    const stripe = await stripePromise
    const result = await stripe.redirectToCheckout({ sessionId: sessionId })

    if (result.error) {
      console.log(result.error.message)
    }
  }

  if (data) {
    checkoutRedirect(data.createSession.id)
  }

  if (billingData) {
    window.location = billingData.billingSession.url
  }

  return (
    <div>
      {isSubscribed ? (
        <>
          <h2 className="font-bold text-xl mb-4">
            You are a Pro Plan subscriber!
          </h2>
          <button
            disabled={billingLoading}
            onClick={billingSession}
            className="btn btn-blue"
          >
            View or modify subscription
          </button>
        </>
      ) : (
        <>
          <Switch.Group
            as="div"
            className="flex flex-col items-center space-y-1 mx-auto"
          >
            <Switch
              checked={isAnnual}
              onChange={setIsAnnual}
              className={`${
                isAnnual ? 'bg-blue-500' : 'bg-green-500'
              } relative inline-flex items-center h-6 rounded-full w-11`}
            >
              <span className="sr-only">Enable notifications</span>
              <span
                className={`${
                  isAnnual ? 'translate-x-6' : 'translate-x-1'
                } inline-block w-4 h-4 transform bg-white rounded-full`}
              />
            </Switch>
            <Switch.Label className="text-xs">Monthly vs. Annual</Switch.Label>
          </Switch.Group>
          <div className="space-y-4 mt-4">
            {plans.map((plan, i) => (
              <div
                key={i}
                className="flex-1 border border-gray-300 p-4 rounded-lg"
              >
                <div>
                  <h2 className="text-lg leading-6 font-medium text-gray-900">
                    {plan.name}
                  </h2>
                  <p className="mt-2">
                    {plan.free ? (
                      <span className="text-3xl font-extrabold text-gray-900">
                        Free
                      </span>
                    ) : (
                      <>
                        <span className="text-3xl font-extrabold text-gray-900">
                          {`$${isAnnual ? plan.annual : plan.monthly}`}
                        </span>{' '}
                        <span className="text-base font-medium text-gray-500">
                          {`${isAnnual ? '/yr' : '/mo'}`}
                        </span>
                      </>
                    )}
                  </p>
                  {!plan.free && (
                    <button
                      disabled={loading}
                      onClick={() =>
                        createSession({
                          variables: {
                            priceId: isAnnual
                              ? process.env.ANNUAL_PRICE_ID
                              : process.env.MONTHLY_PRICE_ID,
                          },
                        })
                      }
                      className="mt-8 block w-full bg-gray-800 border border-gray-800 rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-gray-900"
                    >
                      Buy {plan.name}
                    </button>
                  )}
                </div>
                <div className="pt-6">
                  <h3 className="text-xs font-medium text-gray-900 tracking-wide uppercase">
                    What's included
                  </h3>
                  <ul className="mt-6 space-y-4 -ml-1">
                    {plan.features.map((feature) => (
                      <li key={feature} className="flex space-x-1">
                        <BiCheck
                          className="flex-shrink-0 h-4 w-4 text-green-500"
                          aria-hidden="true"
                        />
                        <span className="text-sm text-gray-500">{feature}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  )
}

export default Subscription
